<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('ordersHistoryList') }}</h2>
      <grid-view-table
        :id="'order-history'"
        :columns="columns"
        :path="'order-history/'+$route.params.id"
        :default-sort="'createdAt,DESC'"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/orders-history';

  export default {
    name: 'OrderHistoryList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns,
      }
    },
  }
</script>
